<div class="auth">
  <img class="auth__logo" src="assets/logo.svg" alt="Gaimin" />
  <div class="auth__title">
    <h1>Reset password</h1>
  </div>

  <form [formGroup]="form" class="form">
    <div class="form__field-group">
      <label for="email">Email</label>
      <input formControlName="email" class="form__field" type="text" placeholder="" id="email" />

      <ng-container *ngIf="form.get('email')!.invalid && form.get('email')!.dirty">
        <span *ngIf="form.get('email')!.hasError('required')" class="form__error"> Email should be present </span>
        <span *ngIf="form.get('email')!.hasError('email')" class="form__error"> Valid email address required </span>
      </ng-container>
    </div>

    <button class="button" (click)="onSubmit()" [disabled]="form.invalid">Submit</button>
  </form>

  <app-loader></app-loader>
</div>
