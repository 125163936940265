<div class="socials-login">
  <a [href]="ssoGoogleAuthUrl" class="socials-login__item">
    <img src="assets/icons/social/google-icon.svg" alt="Google" /> {{ isRegisterForm ? 'Sign up' : 'Sign in' }} with
    Google
  </a>
  <a [href]="ssoDiscordAuthUrl" class="socials-login__item">
    <img src="assets/icons/social/discord-icon.svg" alt="Discord" /> {{ isRegisterForm ? 'Sign up' : 'Sign in' }} with
    Discord
  </a>
</div>
