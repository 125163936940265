import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';
import { AuthTokens } from '../../shared/intarfaces';
import { AUTH_METHODS, AUTH_TYPES } from '../../shared/enums';

@Component({
  selector: 'sso-auth-page',
  templateUrl: './sso-auth-page.component.html',
  styleUrls: ['./sso-auth-page.component.scss']
})
export class SsoAuthPageComponent implements OnInit {
  @AutoUnsubscribe()
  routeParamsSub: Subscription | undefined;
  @AutoUnsubscribe()
  queryParamsSub: Subscription | undefined;

  showSignupForm: boolean = false;
  nameMinLength = 2;
  nameMaxLength = 50;

  form: FormGroup = new FormGroup<any>({
    code: new FormControl('', [Validators.required]),
    firstName: new FormControl('', [
      Validators.required,
      Validators.minLength(this.nameMinLength),
      Validators.maxLength(this.nameMaxLength),
      Validators.pattern('^[a-zA-Z]+(?:[- ][a-zA-Z]+)*$')
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.minLength(this.nameMinLength),
      Validators.maxLength(this.nameMaxLength),
      Validators.pattern('^[a-zA-Z]+(?:[- ][a-zA-Z]+)*$')
    ])
  });

  constructor(
    private toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.routeParamsSub = this.route.params.subscribe((params: Params) => {
      const ssoType = params['type'];
      const method = params['method'];

      this.queryParamsSub = this.route.queryParams.subscribe((queryParams) => {
        this.showSignupForm = false;
        const code = queryParams['code'];

        if (!ssoType || !method || !code) {
          this.router.navigateByUrl('/');
        }

        let request;

        switch (ssoType) {
          case AUTH_TYPES.google:
            switch (method) {
              case AUTH_METHODS.signin:
                request = this.authService.sendGoogleLoginRequest(code);
                break;
              case AUTH_METHODS.signup:
                request = this.authService.sendGoogleSignupRequest(code);
                break;
              case AUTH_METHODS.connect:
                request = this.authService.connectGoogleRequest(code);
                break;
            }
            break;

          case AUTH_TYPES.discord:
            switch (method) {
              case AUTH_METHODS.signin:
                request = this.authService.sendDiscordLoginRequest(code);
                break;
              case AUTH_METHODS.signup:
                this.showSignupForm = true;
                this.form.get('code')?.setValue(code);
                this.presetUserData();
                break;
              case AUTH_METHODS.connect:
                request = this.authService.connectDiscordRequest(code);
                break;
            }
            break;
        }

        request?.subscribe({
          next: (response) => {
            if (response.success) {
              if ('data' in response && response.data) {
                this.authService.successAuthorize(response.data as AuthTokens);
              } else {
                this.toastrService.success('Account connected successfully!');
                this.router.navigateByUrl('/profile');
              }
            } else {
              this.router.navigateByUrl('/');
            }
          },
          error: (error) => {
            this.router.navigateByUrl('/');
          }
        });
      });
    });
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    this.authService.sendDiscordSignupRequest(this.form.value).subscribe((response) => {
      if (response.success && response.data) {
        this.authService.successAuthorize(response.data);
      } else {
        this.router.navigateByUrl('/');
      }
    });
  }

  presetUserData() {
    const userData = this.authService.getPresetUserData();
    if (userData) {
      this.form.get('firstName')?.setValue(userData.firstName ?? '');
      this.form.get('lastName')?.setValue(userData.firstName ?? '');
    }
  }
}
