<div class="auth">
  <img class="auth__logo" src="assets/logo.svg" alt="Gaimin" />
  <div class="auth__title">
    <h3 *ngIf="showSignupForm">Please provide your first and last name to move forward</h3>
  </div>

  <form *ngIf="showSignupForm" [formGroup]="form" class="form">
    <div class="form__field-group">
      <label for="firstName"> First Name </label>
      <input formControlName="firstName" class="form__field" type="text" placeholder="" id="firstName" />
      <ng-container *ngIf="form.get('firstName')!.invalid && form.get('firstName')!.dirty">
        <span *ngIf="form.get('firstName')!.hasError('required')" class="form__error">
          First Name should be present
        </span>

        <ng-container
          *ngIf="
            form.get('firstName')!.hasError('minlength') || form.get('firstName')!.hasError('maxlength');
            else firstName_pattern
          ">
          <span class="form__error"
            >First Name should have <mark>{{ nameMinLength }} - {{ nameMaxLength }}</mark> characters. You currently
            typed <mark>{{ form.get('firstName')!.value!.length }}</mark></span
          >
        </ng-container>

        <ng-template #firstName_pattern>
          <span *ngIf="form.get('firstName')!.hasError('pattern')" class="form__error">
            First Name should have only latin characters, hyphen and <mark>1</mark> space. It must start and end with a
            Latin character.
          </span>
        </ng-template>
      </ng-container>
    </div>

    <div class="form__field-group">
      <label for="lastName"> Last Name</label>
      <input formControlName="lastName" class="form__field" type="text" placeholder="" id="lastName" />
      <ng-container *ngIf="form.get('lastName')!.invalid && form.get('lastName')!.dirty">
        <span *ngIf="form.get('lastName')!.hasError('required')" class="form__error">
          Last Name should be present
        </span>

        <ng-container
          *ngIf="
            form.get('lastName')!.hasError('minlength') || form.get('lastName')!.hasError('maxlength');
            else lastName_pattern
          ">
          <span class="form__error"
            >Last Name should have <mark>{{ nameMinLength }} - {{ nameMaxLength }}</mark> characters. You currently
            typed <mark>{{ form.get('lastName')!.value!.length }}</mark></span
          >
        </ng-container>

        <ng-template #lastName_pattern>
          <ng-container
            ><span *ngIf="form.get('lastName')!.hasError('pattern')" class="form__error">
              Last Name should have only latin characters, hyphen and <mark>1</mark> space. It must start and end with a
              Latin character.
            </span></ng-container
          >
        </ng-template>
      </ng-container>
    </div>
    <input formControlName="code" type="hidden" placeholder="" />
    <button class="button" (click)="onSubmit()" [disabled]="form.invalid">Sign up</button>
  </form>

  <app-loader></app-loader>
</div>
