<div class="change-password">
  <div class="header">
    <h2>Change your password</h2>
    <span class="icon-Close close-icon" title="Close" (click)="closeModal()"></span>
  </div>

  <div class="modal-content">
    <form [formGroup]="form" class="form">
      <div class="form__field-group">
        <label for="oldPassword"> Old Password</label>
        <div class="password-field">
          <input
            formControlName="oldPassword"
            [type]="validatorsService.isPasswordVisible ? 'text' : 'password'"
            class="form__field"
            id="oldPassword"
            #oldPassword />
          <span
            (click)="validatorsService.togglePasswordVisibility()"
            class="icon"
            [ngClass]="{
              'icon-Closed-Eye': !validatorsService.isPasswordVisible,
              'icon-Eye': validatorsService.isPasswordVisible
            }"></span>
        </div>

        <ng-container *ngIf="form.get('oldPassword')!.invalid && form.get('oldPassword')!.dirty">
          <span *ngIf="form.get('oldPassword')!.hasError('required')" class="form__error">
            Old Password should be present
          </span>
        </ng-container>
      </div>
      <div class="form__field-group">
        <label for="newPassword"> New Password</label>
        <div class="password-field">
          <input
            formControlName="newPassword"
            [type]="validatorsService.isRepeatPasswordVisible ? 'text' : 'password'"
            class="form__field"
            id="newPassword"
            #newPassword />
          <span
            (click)="validatorsService.togglePasswordVisibility(true)"
            class="icon"
            [ngClass]="{
              'icon-Closed-Eye': !validatorsService.isRepeatPasswordVisible,
              'icon-Eye': validatorsService.isRepeatPasswordVisible
            }"></span>
        </div>

        <ng-container *ngIf="passwordControl.invalid && passwordControl.dirty">
          <span *ngIf="passwordControl.hasError('required')" class="form__error"> Password should be present </span>

          <span
            *ngIf="
              passwordControl.hasError('minlength') || passwordControl.hasError('maxlength');
              else password_allowedPattern
            "
            class="form__error">
            Password should have
            <mark>{{ validatorsService.passwordMinLength }} - {{ validatorsService.passwordMaxLength }}</mark>
            characters. You currently typed
            <mark>{{ passwordControl.value!.length }}</mark>
          </span>

          <ng-template #password_allowedPattern>
            <ng-container *ngIf="passwordControl.hasError('allowedPattern'); else password_complexity">
              <span class="form__error">{{ validatorsService.allowedPatternError }}</span>
            </ng-container>
          </ng-template>

          <ng-template #password_complexity>
            <ng-container *ngIf="passwordControl.hasError('complexity'); else password_username">
              <span class="form__error">{{ validatorsService.complexityError }}</span>
            </ng-container>
          </ng-template>

          <ng-template #password_username>
            <span *ngIf="passwordControl.hasError('notContainUsername')" class="form__error">
              {{ validatorsService.usernameError }}</span
            >
          </ng-template>
        </ng-container>

        <ng-container *ngIf="form.invalid && form.hasError('fieldsMatch')">
          <span class="form__error">New password cannot be the same as the old password.</span>
        </ng-container>
      </div>

      <button class="button" (click)="onSubmit()" [disabled]="form.invalid">Change password</button>
    </form>
  </div>
</div>
