export enum AUTH_METHODS {
  signin = 'signin',
  signup = 'signup',
  connect = 'connect'
}

export enum AUTH_TYPES {
  google = 'google',
  discord = 'discord'
}

export enum CHAINS {
  BNB_CHAIN = 'BNB_CHAIN',
  BNB_CHAIN_TESTNET = 'BNB_CHAIN_TESTNET'
}
