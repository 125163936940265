export const environment = {
  production: false,
  gaiminApi: 'https://api.katya.auth.gaimin.io/api',
  oauth: {
    google: {
      authUrl:
        'https://accounts.google.com/o/oauth2/v2/auth?scope=email openid https://www.googleapis.com/auth/userinfo.profile&include_granted_scopes=true&response_type=code&service=lso&o2v=2&ddm=0&flowName=GeneralOAuthFlow',
      clientId: '263013827022-76o5t6d4qotfqa498i15bkrejjb8o2og.apps.googleusercontent.com',
      signin: 'https://katya.auth.gaimin.io/auth/callback/google/signin',
      signup: 'https://katya.auth.gaimin.io/auth/callback/google/signup',
      connect: 'https://katya.auth.gaimin.io/auth/callback/google/connect'
    },
    discord: {
      authUrl: 'https://discord.com/oauth2/authorize?response_type=code&scope=identify+email',
      clientId: '1276160962755297383',
      signin: 'https://katya.auth.gaimin.io/auth/callback/discord/signin',
      signup: 'https://katya.auth.gaimin.io/auth/callback/discord/signup',
      connect: 'https://katya.auth.gaimin.io/auth/callback/discord/connect'
    }
  },
  openfort: {
    publicKey: 'pk_test_571a7d5c-affd-5a08-99f6-2246fb272233',
    shieldApiKey: '8fa88641-cbdb-4d24-8846-31d7d9f94b9e',
    chainId: 97
  }
};
