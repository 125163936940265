import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {
  private readonly HIDE_PASSWORD_DELAY: number = 3000;
  private timeOut!: ReturnType<typeof setTimeout>;
  private timeOutRepeat!: ReturnType<typeof setTimeout>;
  isPasswordVisible: boolean = false;
  isRepeatPasswordVisible: boolean = false;

  readonly passwordMinLength: number = 8;
  readonly passwordMaxLength: number = 50;

  // Pattern for allowed characters [a-zA-Z0-9] + special characters: !";#$%&'()*+,-./:;<=>?@[]^_`{|}~
  readonly allowedPatternRegex = new RegExp('^[A-Za-z0-9!"#\\$%&\'()*+,-./:;<=>?@[\\]^_`{|}~]+$');
  readonly allowedPatternError =
    'Password contains invalid characters. Allowed: latin letters, digits, and !";#$%&\'()*+,-./:;<=>?@[]^_`{|}~.';

  // Complexity rule: At least one uppercase, one lowercase, one digit, and one special character
  readonly complexityRegex = new RegExp(
    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#\\$%&\'()*+,-./:;<=>?@[\\]^_`{|}~]).*$'
  );
  readonly complexityError =
    'Password should contain at least 1 lowercase character, 1 uppercase character, 1 digit, and 1 symbol';

  readonly usernameError = 'Password should not contain part of your username';

  constructor() {}

  passwordValidators(includeComplexity: boolean, includeUsernameCheck: boolean, username?: string): ValidatorFn[] {
    const validators = [
      Validators.required,
      Validators.minLength(this.passwordMinLength),
      Validators.maxLength(this.passwordMaxLength),
      this.allowedPatternValidator()
    ];

    if (includeComplexity) {
      validators.push(this.complexityValidator());
    }

    if (includeUsernameCheck && username) {
      validators.push(this.notContainUsernameValidator(username));
    }

    return validators;
  }

  // Pattern validator for allowed characters
  allowedPatternValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const valid = this.allowedPatternRegex.test(control.value);
      return valid ? null : { allowedPattern: true };
    };
  }

  // Complexity validator for password rules
  complexityValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const valid = this.complexityRegex.test(control.value);
      return valid ? null : { complexity: true };
    };
  }

  notContainUsernameValidator(username: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.value;

      if (!password || !username) {
        return null;
      }

      const lowerPassword = password.toLowerCase();
      const lowerUsername = username.toLowerCase();

      if (lowerPassword.includes(lowerUsername)) {
        return { notContainUsername: true };
      }

      return null;
    };
  }

  fieldsMatchValidator(controlName1: string, controlName2: string, shouldMatch: boolean): ValidatorFn {
    return (group: AbstractControl): { [key: string]: boolean } | null => {
      const control1 = group.get(controlName1)?.value;
      const control2 = group.get(controlName2)?.value;

      const fieldsMatch = control1 && control2 && control1 === control2;

      if ((shouldMatch && !fieldsMatch) || (!shouldMatch && fieldsMatch)) {
        return { fieldsMatch: true };
      }

      return null;
    };
  }

  togglePasswordVisibility(isRepeatPassword: boolean = false) {
    if (isRepeatPassword) {
      this.isRepeatPasswordVisible = !this.isRepeatPasswordVisible;
      clearTimeout(this.timeOutRepeat);
      this.timeOutRepeat = setTimeout(() => {
        this.isRepeatPasswordVisible = false;
      }, this.HIDE_PASSWORD_DELAY);
    } else {
      this.isPasswordVisible = !this.isPasswordVisible;
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        this.isPasswordVisible = false;
      }, this.HIDE_PASSWORD_DELAY);
    }
  }
}
