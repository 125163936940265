import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninPageComponent } from './pages/signin-page/signin-page.component';
import { SignupPageComponent } from './pages/signup-page/signup-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { SsoAuthPageComponent } from './pages/sso-auth-page/sso-auth-page.component';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { PolicyPageComponent } from './pages/policy-page/policy-page.component';

import { AuthGuard } from './guards/auth.guard';
import { RedirectIfLoggedInGuard } from './guards/redirect-auth.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'signin',
        component: SigninPageComponent,
        canActivate: [RedirectIfLoggedInGuard]
      },
      {
        path: 'signup',
        component: SignupPageComponent,
        canActivate: [RedirectIfLoggedInGuard]
      },
      {
        path: 'sign-up/confirm',
        component: ProfilePageComponent
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordPageComponent
      },
      {
        path: 'password/reset',
        component: ResetPasswordPageComponent
      },
      {
        path: 'profile',
        component: ProfilePageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'auth/callback/:type/:method',
        component: SsoAuthPageComponent
      },
      {
        path: 'eula',
        component: TermsPageComponent
      },
      {
        path: 'policy',
        component: PolicyPageComponent
      },
      { path: '**', redirectTo: '/signin', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
