import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AUTH_METHODS } from '../../enums';

@Component({
  selector: 'app-socials-login',
  templateUrl: './socials-login.component.html',
  styleUrls: ['./socials-login.component.scss']
})
export class SocialsLoginComponent implements OnInit {
  @Input() isRegisterForm: boolean = false;

  ssoGoogleAuthUrl: string = '';
  ssoDiscordAuthUrl: string = '';

  constructor(private readonly authService: AuthService) {}

  ngOnInit(): void {
    const method: AUTH_METHODS = this.isRegisterForm ? AUTH_METHODS.signup : AUTH_METHODS.signin;
    this.ssoGoogleAuthUrl = this.authService.getGoogleAuthUrl(method);
    this.ssoDiscordAuthUrl = this.authService.getDiscordAuthUrl(method);
  }
}
