import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { map, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../shared/services/auth.service';

export const RedirectIfLoggedInGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);

  return authService.isLoggedIn$.pipe(
    map((loggedIn) => {
      if (loggedIn) {
        const queryParams = state.root.queryParams;
        router.navigate(['/profile'], { queryParams });
        return false;
      } else {
        return true;
      }
    }),
    catchError((err) => {
      console.log('RedirectIfLoggedInGuard error:', err);
      return of(true);
    })
  );
};
